import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#02385d", // This is a shade of blue. Adjust as needed.
    },
    // Add other colors as needed
  },
});

export default theme;
