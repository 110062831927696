import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Email, Phone } from "@mui/icons-material";
import { Helmet } from "react-helmet";

const ContactUsPage: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Contact Us - My Smile Dental Care</title>
        <meta
          name="description"
          content="We provide a Wide range of Dental Care from Basic
          Diagnosis and Routine Dental Procedures to Complex
          Reconstructive Surgeries. For All Appointments and
          Inquiries, Please Call Us."
        />
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Dentist",
              name: "My Smile Dental Care",
              url: "https://www.mysmiledentalcare.in/contact",
              telephone: "+91 9663016867",
              address: {
                "@type": "PostalAddress",
                streetAddress: "No 14, Ranjini Places",
                addressLocality: "Bengaluru",
                postalCode: "560076",
                addressCountry: "IN",
              },
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+91 9663016867",
                contactType: "Customer service",
                areaServed: "IN",
                availableLanguage: ["English", "Hindi", "Telugu", "Kannada"],
              },
            })}
          </script>
        </Helmet>
      </Helmet>
      <Container>
        <Box sx={{ padding: "20px" }}>
          <Grid container spacing={4}>
            {/* First Column */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: "#f2f2f2",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <Typography variant="h1" gutterBottom>
                  <h1 className="title">Contact Us</h1>
                </Typography>
                <Typography variant="body1">
                  <strong>Address:</strong> <br />
                  First floor, No 14, Ranjini Palace
                  <br /> Kodichikkanahalli Main Rd, near RMR Park,
                  <br /> Manjunatha Layout, <br />
                  Kodichikknahalli, Bommanahalli, <br />
                  Bengaluru, Karnataka 560076
                </Typography>
                <br />
                <br />
                <strong>Contact:</strong> <br />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Phone sx={{ marginRight: 1 }} />
                  <Typography variant="body2" sx={{ marginRight: 2 }}>
                    +91 9663016867
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Email sx={{ marginRight: 1 }} />
                  <Typography variant="body2">
                    mysmiledentalcare9@gmail.com
                  </Typography>
                </Box>
                <br />
                <br />
                <Typography variant="body1">
                  <strong>Opening Hours:</strong> <br />
                  Morning: 9:30 AM - 1:30 PM
                  <br />
                  Evening: 4:30 PM - 8:30 PM
                </Typography>
              </Box>
            </Grid>
            {/* Second Column */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: "#f2f2f2",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                {/* Google Map Component Goes Here */}
                {/* Replace this with your Google Map component */}
                <Typography variant="h5" gutterBottom>
                  Google Map
                </Typography>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.2222049713882!2d77.61175517574718!3d12.893428587414856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15e6498465f3%3A0xdd5145d155454cfb!2sMy%20Smile%20Dental%20Care!5e0!3m2!1sen!2sin!4v1712432915772!5m2!1sen!2sin"
                  width="500"
                  height="450"
                  style={{ border: "0" }}
                  allowFullScreen={true}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default ContactUsPage;
