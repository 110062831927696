import React, { useState, useEffect } from "react";
import { Box, Typography, Container } from "@mui/material";
import backgroundImage from "../../assets/images/hero-bg.webp";
import { useNavigate } from "react-router-dom";
import "./Hero.css";

const Hero: React.FC = () => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const navigate = useNavigate();

  const heroItems = [
    {
      title: "Welcome to My Smile Dental Care",
      description:
        "We provide comprehensive dental care with a focus on patient comfort and satisfaction. Our experienced team offers a wide range of services, including preventive care, cosmetic dentistry, and restorative treatments. With state-of-the-art facilities and personalized care, we strive to help our patients achieve optimal oral health and beautiful smiles",
      link: "services",
    },
    {
      title: "Services",
      description:
        "Explore a range of services at My Smile Dental Care, including Root Canal Treatment, Implants, Clear Aligners, Crowns & Bridges, Teeth Whitening, and more. Discover our comprehensive offerings and achieve your perfect smile with us in the services section.",
      link: "about",
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentItemIndex((prevIndex) => (prevIndex + 1) % heroItems.length);
    }, 10000);

    return () => clearTimeout(timeout);
  }, [currentItemIndex, heroItems.length]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "40vh",
        minHeight: "300px",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "0 20px",
      }}
      aria-live="polite"
    >
      <Container maxWidth="sm">
        <div style={{ textAlign: "left" }}>
          {heroItems.map((item, index) => (
            <div
              key={index}
              className={`hero-item${
                currentItemIndex === index ? " visible" : ""
              }`}
              style={{ display: currentItemIndex === index ? "block" : "none" }}
            >
              <Container maxWidth="md">
                <Typography
                  component="h2"
                  variant="h4"
                  gutterBottom
                  style={{ color: "#f2f4f6" }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ color: "#f2f4f6" }}
                >
                  {item.description}
                </Typography>
              </Container>
            </div>
          ))}
        </div>
      </Container>
    </Box>
  );
};

export default Hero;
