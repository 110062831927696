import React from "react";
import { Container, Grid, Typography, Paper } from "@mui/material";
import "./OurServices.css"; // Import the CSS file for styling
import RootCanalImage from "../../assets/images/rootcanal-service.webp";
import ImplantsImage from "../../assets/images/implants-service.jpeg";
import ClearAligners from "../../assets/images/clear-aligners-service.webp";
import Crowns from "../../assets/images/dentalcrown-service.png";
import TeethWhitening from "../../assets/images/teeth-whitening-service.png";
import Dentures from "../../assets/images/dentures-service.webp";
import Braces from "../../assets/images/braces-service.webp";
import Extractions from "../../assets/images/extraction-service.png";
import Fillings from "../../assets/images/fillings-service.jpg";
import SmileDesign from "../../assets/images/smile design service.png";
import GumTreatment from "../../assets/images/gum treatment service.png";
import KidsDentistry from "../../assets/images/kids dentiistry service.png";

// SVG icon of a tooth
const ToothIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    viewBox="0 0 24 24"
    height="100"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M15 10.21c0-1.66-1.34-3-3-3s-3 1.34-3 3V12h6v-1.79zM16 16c-.83 0-1.54-.5-1.84-1.22l-1.56-3.12c-.1-.21-.29-.36-.52-.36H9.92c-.23 0-.42.15-.52.36l-1.56 3.12A1.993 1.993 0 0 1 6 16c0-.34.08-.65.21-.93L7.39 13H5c-.55 0-1 .45-1 1s.45 1 1 1h2c.25 0 .48-.15.58-.38L8.72 14h6.56l.15.62c.1.24.33.38.58.38h2c.55 0 1-.45 1-1s-.45-1-1-1h-2.39l1.18-2.93c.13.28.21.59.21.93zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
  </svg>
);

export const servicesList = [
  {
    label: "Rootcanal",
    alt: "Root Canal - My Smile Dental Care",
    image: RootCanalImage,
    pdfName: "rootcanal",
    shortDescription:
      "A root canal treatment is a dental procedure to remove inflamed or infected pulp on the inside of the tooth which is then carefully cleaned and disinfected, then filled and sealed. Root canal treatment is designed to eliminate bacteria from the infected root canal, prevent reinfection of the tooth and save the natural tooth.",
  },
  {
    label: "Implants",
    alt: "Implants - My Smile Dental Care",
    image: ImplantsImage,
    pdfName: "implants",
    shortDescription:
      "A dental implant is a prosthetic replacement for a missing tooth. Natural teeth consist of the crown and the root. The crown is the visible section that is covered with white enamel. Supporting the crown is the tooth root which extends into the jawbone.",
  },
  {
    label: "Clear Aligners",
    alt: "Clear Aligners - My Smile Dental Care",
    image: ClearAligners,
    pdfName: "alignars",
    shortDescription:
      "Clear aligners are nearly invisible plastic shells that snap over your teeth. As you wear them, they apply gentle pressure on your teeth to gradually reposition them. If you have mild to moderate orthodontic problems, aligner treatments may be the orthodontic solution you’ve been looking for.",
  },
  {
    label: "Crowns & Bridges",
    alt: "Crowns &amp; Bridges - My Smile Dental Care",
    image: Crowns,
    pdfName: "crowns",
    shortDescription:
      "Both crowns and most bridges are fixed prosthetic devices. Unlike removable devices such as dentures, which you can take out and clean daily, crowns and bridges are cemented onto existing teeth or implants, and can only be removed by a dentist. A crown is used to entirely cover or 'cap' a damaged tooth. A bridge may be recommended if you're missing one or more teeth.",
  },
  {
    label: "Teeth Whitening",
    alt: "Teeth Whitening - My Smile Dental Care",
    image: TeethWhitening,
    pdfName: "teethwhitening",

    shortDescription:
      "This is process of brightening your yellowish teeth into better white with the help of teeth whitening gels with the professional help. ",
  },
  {
    label: "Dentures",
    alt: "Dentures - My Smile Dental Care",
    image: Dentures,
    pdfName: "dentures",

    shortDescription:
      "Full dentures, or complete dentures, are removable devices used to replace missing teeth that consist of both upper and lower sets .The denture teeth are made from porcelain or acrylic and are held together by an acrylic or metal base. Full dentures may be needed when you lose all of your teeth; they can help you to speak and eat normally, and fill out your appearance and help the muscles in your face and jaw work properly, leaving you feeling more confident to smile.",
  },
  {
    label: "Braces Treatment",
    alt: "Braces Treatment - My Smile Dental Care",
    image: Braces,
    pdfName: "bracestreatment",
    shortDescription:
      "•	Spacing and crowding of the teeth are the main culprits for esthetic and confident smile. Braces / tooth braces are the solutions for straightening/ aligning these teeth in a symmetric position to achieve a confident smile.",
  },
  {
    label: "Extractions",
    alt: "Extractions - My Smile Dental Care",
    image: Extractions,
    pdfName: "extractions",
    shortDescription:
      "Wisdom teeth or third molars are the last permanent teeth to appear in the oral cavity. These usually erupt between the ages of 17 to 25 and are known to cause problems for many due to a lack of space in the jaw. If the tooth doesn't have room to grow (impacted wisdom tooth), it might result in pain, infection, or other dental problems and you'll likely need to have it pulled.",
  },
  {
    label: "Fillings",
    alt: "Fillings - My Smile Dental Care",
    image: Fillings,
    pdfName: "fillings",
    shortDescription:
      "Tooth fillings are a type of treatment used to replace tooth structure that has been lost due to decay or trauma. In a Tooth filling the decayed and damaged portion of the  and a dental material is placed to prevent further damage and restore function and appearance.",
  },
  {
    label: "Smile Designing",
    alt: "Smile Designing - My Smile Dental Care",
    image: SmileDesign,
    pdfName: "smiledesigning",
    shortDescription:
      "Uneven teeth are a common dental issue that affects people of all ages. There are many reasons you may have crooked teeth that include genetics, bad oral hygiene, thumb sucking during childhood or damage to your teeth through an accident.",
  },
  {
    label: "Gum Treatments",
    alt: "Gum Treatments - My Smile Dental Care",
    image: GumTreatment,
    pdfName: "gumtreatments",
    shortDescription:
      "If the gum diseases spreads deep will cause problem to the attachment between gum and tooth called as periodontal disease. Ignored periodontal disease will result in fast spread of gum disease from one tooth to multiple tooth and results in attachment loss and finally tooth loss.",
  },
  {
    label: "Kids Dentistry",
    alt: "Kids Dentistry - My Smile Dental Care",
    image: KidsDentistry,
    pdfName: "kidsdentistry",
    shortDescription:
      "Milk teeth are more susceptible to caries and there is a high chance that the infections from the milk teeth will spread directly to the permanent tooth. Kids Dentistry deals with the problems associated with milk teeth and the oral habits of children through adolescence. Without proper dental care, children are at risk of developing tooth decay and disease, which can lead to a lifetime of pain and complications. About 80% of children suffer from dental caries.",
  },
];

const OurServices: React.FC = () => {
  return (
    <div>
      {/* <Helmet>
        <title>Services - My Smile Dental Care</title>
        <meta
          name="description"
          content="Explore a wide range of dental services at My Smile Dental Care including preventive, cosmetic, and restorative dentistry. Find the perfect treatment tailored to your needs."
        />
      </Helmet> */}
      <Container maxWidth="lg" className="our-services-container">
        {/* Heading */}
        <Typography variant="h3" align="center" gutterBottom>
          <h3 className="title">Our Services</h3>
        </Typography>
        {/* Grids */}
        <Grid container spacing={2} className="services-grid">
          {servicesList.map((_, index) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={2}
              key={index}
              className="grid-item"
            >
              <Paper elevation={3} className="service-item">
                {/* Replace 'imageSrc' with your actual image source */}
                {/* <img
                src="https://via.placeholder.com/150"
                alt="Service"
                style={{ width: "100%", height: "auto" }}
              /> */}
                <ToothIcon />
                <Typography variant="h4" align="center" gutterBottom>
                  <h4 className="service-label">{_.label}</h4>
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default OurServices;
