import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import { Email, Phone, Room } from "@mui/icons-material";

const Footer: React.FC = () => {
  // Get the current year
  const currentYear = new Date().getFullYear();

  return (
    <Box component="footer" style={{ marginTop: "50px" }}>
      {/* First row */}

      <Box sx={{ backgroundColor: "#7da0b6", padding: "20px", color: "#fff" }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Contact Us:</strong> <br />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Phone sx={{ marginRight: 1 }} />
                  <Typography variant="body2" sx={{ marginRight: 2 }}>
                    +91 9663016867
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Email sx={{ marginRight: 1 }} />
                  <Typography variant="body2">
                    mysmiledentalcare9@gmail.com
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Room sx={{ marginRight: 1 }} />
                  <a
                    href="https://maps.app.goo.gl/tc1zJnxbiyxhKKUL6"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#FFF" }}
                  >
                    <Typography variant="body2">View on Map</Typography>
                  </a>
                </Box>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4} textAlign={"left"}>
              <Typography variant="body1">
                <strong>Opening Hours:</strong> <br />
                Morning: 9:30 AM - 1:30 PM
                <br />
                Evening: 4:30 PM - 8:30 PM
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              {/* <Typography variant="body1">
                At My Smile Dental Care, your satisfaction and comfort are our
                primary goals. Our dedicated team of dental professionals is
                committed to providing high-quality care in a warm and friendly
                atmosphere. <br />
                <br />
                Trust My Smile Dental Care for all your dental needs and let
                us help you maintain a healthy, confident smile for life.
              </Typography> */}
              <Typography variant="body1">
                <strong>Address:</strong> <br />
                First floor, No 14, Ranjini Palace
                <br /> Kodichikkanahalli Main Rd, near RMR Park,
                <br /> Manjunatha Layout, <br />
                Kodichikknahalli, Bommanahalli, Bengaluru, Karnataka 560076
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Second row */}
      <Box sx={{ backgroundColor: "#6a8f9d", padding: "20px", color: "#fff" }}>
        <Typography variant="body2" align="center">
          &copy; {currentYear} My Smile Dental Care. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
