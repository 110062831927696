import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Container,
  Drawer,
  List,
  ListItemButton,
  useMediaQuery,
  Theme,
  useTheme,
  Typography,
  Box,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";

// Ensure the correct path to your images
import AppIcon from "../../assets/images/app-icon-v2.png";
import AppTitle from "../../assets/images/app-title.png";

const GradientAppBar = styled(AppBar)(({ theme }) => ({
  background: "linear-gradient(0deg, #02385d, #337fa7)",
}));

const Navigation: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Drawer
      anchor="bottom"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.paper, // Set the drawer background color
        },
      }}
    >
      <List>
        {["Home", "Services", "About", "Contact"].map((text) => (
          <ListItemButton
            key={text}
            component={NavLink}
            to={text === "Home" ? "/" : `/${text.toLowerCase()}`}
            onClick={() => setMobileOpen(false)}
            sx={{
              justifyContent: "center",
              "&.active": {
                fontWeight: "bold",
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.light,
              },
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            {text}
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );

  return (
    <>
      <GradientAppBar
        position="static"
        sx={{ bgcolor: theme.palette.primary.main, height: "75px" }}
      >
        <Container>
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { xs: "flex", md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: "flex" }, flexGrow: 1 }}>
              <NavLink
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#FFF",
                  display: "flex",
                }}
              >
                <img
                  src={AppIcon}
                  alt="App Icon"
                  style={{ marginRight: "20px", height: "50px" }}
                />
                <Typography
                  style={{
                    fontWeight: 900,
                    textTransform: "uppercase",
                    fontSize: isMobile ? "1.75rem" : "2rem",
                    position: "relative",
                    top: "5px",
                  }}
                >
                  My Smile Dental Care
                </Typography>
              </NavLink>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
              }}
            >
              {["Home", "Services", "About", "Contact"].map((text) => (
                <Button
                  key={text}
                  component={NavLink}
                  to={text === "Home" ? "/" : `/${text.toLowerCase()}`}
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    "&.active": {
                      backgroundColor: theme.palette.primary.light,
                    },
                  }}
                >
                  {text}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </GradientAppBar>
      {isMobile && drawer}
    </>
  );
};

export default Navigation;
