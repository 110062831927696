import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

const AboutPage: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>About Us - My Smile Dental Care</title>
        <meta
          name="description"
          content="My Smile Dental Care stands as a beacon of hope for
          those seeking Comprehensive Dental Solutions.
          Renowned for its Exceptional Services and Patient-
          Centric Approach."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Dentist",
            name: "My Smile Dental Care",
            description:
              "Dedicated to providing excellent dental care, My Smile Dental Care features a seasoned team of professionals committed to ensuring every patient's dental health and well-being.",
            url: "https://www.mysmiledentalcare.in/about",
            founder: [
              {
                "@type": "Person",
                name: "Dr. Chandra Mouli",
                jobTitle: "Chief Dental Surgeon",
              },
            ],
            address: {
              "@type": "PostalAddress",
              streetAddress: "No 14, Ranjini Palace",
              addressLocality: "Bengaluru",
              postalCode: "560076",
              addressCountry: "IN",
            },
          })}
        </script>
      </Helmet>

      <Container>
        <Box component="section" sx={{ paddingTop: "20px" }}>
          <Typography component="h1" variant="h4">
            <h1 className="title">About My Smile Dental Care</h1>
          </Typography>
          <Typography paragraph>
            Nestled in the heart of Bangalore city, My Smile Dental Care stands
            as a beacon of hope for those seeking comprehensive dental
            solutions. Renowned for its exceptional services and patient-centric
            approach, the clinic offers a wide range of treatments from routine
            check-ups to advanced cosmetic procedures, ensuring every patient
            achieves optimal oral health and confidence in their smile.
          </Typography>
          <Typography variant="h2" gutterBottom>
            <h2 className="title">Commitment to Excellence</h2>
          </Typography>
          <Typography paragraph>
            My Smile Dental Care is built on the belief that everyone deserves a
            beautiful smile. The clinic combines meticulous care with advanced
            technology to provide personalized treatments in a comfortable
            environment. Each staff member is not only highly skilled but also
            empathetic, addressing patient anxiety and ensuring a relaxing
            experience.
          </Typography>
          <Typography variant="h2" gutterBottom>
            <h2 className="title">Comprehensive Dental Services</h2>
          </Typography>
          <Typography paragraph>
            The clinic shines with its array of services that cater to both
            general and specialized dental needs. Preventive measures like
            cleanings and exams are fundamental, while corrective procedures
            such as fillings and root canals restore dental health effectively.
            Cosmetic treatments, including teeth whitening and veneers, are
            performed using state-of-the-art technology, yielding
            natural-looking and durable results.
          </Typography>
          <Typography variant="h2" gutterBottom>
            <h2 className="title">Advanced Technology and Techniques</h2>
          </Typography>
          <Typography paragraph>
            My Smile Dental Care prides itself on leveraging cutting-edge
            technology. Tools such as digital X-rays and intraoral cameras
            enhance diagnostic accuracy and patient understanding of their oral
            health conditions. The clinic also specializes in dental implants, a
            transformative solution for tooth loss that mimics natural tooth
            function and promotes bone growth.
          </Typography>
          <Typography variant="h2" gutterBottom>
            <h2 className="title">Patient-Centered Care</h2>
          </Typography>
          <Typography paragraph>
            Understanding the apprehension associated with dental visits, My
            Smile Dental Care emphasizes comfort and patient engagement. The
            clinic’s serene interiors and detailed discussions about treatment
            options ensure patients are well-informed and comfortable throughout
            their procedures. Flexible scheduling and various financing options
            also make treatments accessible and stress-free.
          </Typography>
          <Typography variant="h2" gutterBottom>
            <h2 className="title">Community Involvement and Education</h2>
          </Typography>
          <Typography paragraph>
            Beyond clinical services, My Smile Dental Care is actively involved
            in community education about oral health. The team's participation
            in health fairs and school programs not only raises awareness but
            also strengthens community ties, showcasing the clinic's commitment
            to public health.
          </Typography>
          <Typography variant="h2" gutterBottom>
            <h2 className="title">A Legacy of Trust and Transformation</h2>
          </Typography>
          <Typography paragraph>
            Patients of My Smile Dental Care frequently praise the high-quality
            care and transformative outcomes they experience. The clinic's focus
            on building lasting relationships and enhancing patients' quality of
            life through superior dental care has made it a cornerstone of
            community health.
          </Typography>
          <Typography paragraph>
            In conclusion, My Smile Dental Care represents a perfect blend of
            professional expertise and compassionate care. With its advanced
            treatment options, patient-focused services, and community
            involvement, the clinic stands out as a premier destination for
            those seeking to maintain or enhance their dental health. Here, each
            smile is treated as a unique canvas, and every patient receives the
            respect and care they deserve, making My Smile Dental Care a true
            champion of dental well-being.
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default AboutPage;
