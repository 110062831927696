import React from "react";
import Hero from "../../common/Hero";
import WeCare from "../../common/WeCare";
import OurServices from "../../common/OurServices";
import { Helmet } from "react-helmet";

const HomePage: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Best Dental Clinic in Bangalore - My Smile Dental Care</title>
        <meta
          name="description"
          content="My Smile Dental Care One of the Best Dental Clinics in
          Bangalore. We provide the Best Dental Care Services
          from Our Top Dentist in Bangalore"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Dentist",
            name: "My Smile Dental Care",
            address: {
              "@type": "PostalAddress",
              streetAddress: "No 14, Ranjini Palace",
              addressLocality: "Bengaluru",
              postalCode: "560076",
              addressCountry: "IN",
            },
            geo: {
              "@type": "GeoCoordinates",
              latitude: "12.913965",
              longitude: "77.604009",
            },
            telephone: "+91 9663016867",
            openingHoursSpecification: [
              {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ],
                opens: "09:30",
                closes: "20:30",
              },
            ],
            url: "https://www.mysmiledentalcare.in",
            sameAs: [
              "https://www.facebook.com/mysmiledentalcare",
              "https://www.instagram.com/mysmiledentalcare",
            ],
          })}
        </script>
      </Helmet>

      <div>
        <Hero />
        <WeCare />
        <OurServices />
        {/* Other components for your homepage */}
      </div>
    </div>
  );
};

export default HomePage;
