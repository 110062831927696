import { Helmet } from "react-helmet";
import {
  Typography,
  Box,
  List,
  ListItem,
  Container,
  Grid,
} from "@mui/material";
import { servicesList } from "../../common/OurServices";
import ComingSoonPage from "../../common/ComingSoon";
import ServiceCard from "./ServiceCard";

const ServicesPage = () => (
  <div>
    <Helmet>
      <title>Best Dental Treatment in Bangalore - My Smile Dental Care</title>
      <meta
        name="description"
        content="My Smile Dental Care provides the Best Dental
        Treatment in Bangalore at Affordable Prices. Explore
        Dental Treatments provided by Skilled Professionals
        Dedicated to Oral Health."
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Dentist",
            name: "My Smile Dental Care",
            url: "https://www.mysmiledentalcare.in/services",
            serviceProvided: [
              {
                "@type": "Service",
                name: "Root Canal Treatment",
                description:
                  "A treatment to repair and save a badly damaged or infected tooth instead of removing it.",
              },
              {
                "@type": "Service",
                name: "Dental Implants",
                description:
                  "Dental implants are surgical components that replace the roots of missing teeth.",
              },
              // Additional services here
            ],
          })}
        </script>
      </Helmet>
    </Helmet>

    <div>
      <Container>
        <Box
          component="section"
          textAlign="left"
          style={{ paddingTop: "20px" }}
        >
          <Typography component="h1" variant="h4">
            <h1 className="title">Dental Services in Bangalore</h1>
          </Typography>
          <Typography
            variant="body1"
            mt={2} // Adds margin top for spacing
          >
            Explore premier dental services at My Smile Dental Care, located in
            the heart of Bengaluru. Our state-of-the-art clinic specializes in a
            comprehensive range of treatments from preventive care to
            sophisticated cosmetic and restorative dentistry. Whether you're
            looking for routine dental exams, advanced root canal treatments, or
            cutting-edge cosmetic enhancements like clear aligners and veneers,
            our dedicated team of experienced professionals is committed to
            ensuring you achieve and maintain a healthy, beautiful smile.
            Discover our personalized approach to dental wellness in a
            comforting and safe environment tailored to meet the needs of every
            family member.
          </Typography>
        </Box>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          {servicesList.map((service: any, index: number) => {
            return <ServiceCard key={"service" + index} data={service} />;
          })}
        </Grid>
      </Container>
      {/* Add more content here */}
    </div>
  </div>
);

export default ServicesPage;
