import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import WeCareIcon from "../../assets/images/we-care.webp";
import "./WeCare.css";

const WeCare: React.FC = () => {
  return (
    <Container maxWidth="lg" style={{ padding: "50px 0px" }}>
      <Grid container spacing={3}>
        {/* Image Section */}
        <Grid
          item
          xs={12}
          md={4}
          className="image-section"
          style={{ padding: "10px" }}
        >
          {/* Replace 'imageSrc' with your actual image source */}
          <img
            src={WeCareIcon}
            alt="Description of the image"
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>
        {/* Text Section */}
        <Grid item xs={12} md={8} style={{ padding: "0" }}>
          <Box
            display="flex"
            // justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <div style={{ padding: "25px" }}>
              <div style={{ fontSize: "2.5rem" }}>
                <h2 className="title">We Care About your teeth!</h2>
              </div>
              <div style={{ fontSize: "1.25rem" }}>
                <p>
                  At My Smile Dental Care, we believe a great smile is the
                  gateway to your well-being. Our dedicated team uses the latest
                  dental technology to offer comprehensive care in a comfortable
                  setting. From preventive maintenance to cosmetic
                  transformations, we tailor our services to meet your unique
                  needs, ensuring every visit leaves you with a healthier,
                  brighter smile.
                </p>
                <p>
                  We're not just about dental excellence; we're about creating a
                  positive impact on your life. With a focus on personalized
                  care, we strive to make every experience at our clinic
                  reassuring and effective. Trust us to be your partners in
                  maintaining not just oral health, but in fostering your
                  overall happiness and confidence.
                </p>
                {/* <p>
                  Embracing a holistic approach, we at My Smile Dental Care
                  extend beyond traditional dentistry to support your entire
                  well-being. Let us care for your smile and be a part of your
                  journey to a healthier, more vibrant you.
                </p> */}
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WeCare;
