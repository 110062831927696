import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./theme"; // Adjust the path according to your file structure
import Navigation from "./common/Nav";
import HomePage from "./pages/Home";
import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";
import ServicesPage from "./pages/Services";
// Import other components or pages as needed
import "./App.css";
import Footer from "./common/Footer";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Ensures baseline styles are consistent */}
      <Router>
        <Navigation />
        <div style={{ width: "100vw", padding: 0 }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/contact" element={<ContactPage />} />
            {/* Add other routes as needed */}
          </Routes>
        </div>
      </Router>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
