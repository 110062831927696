import React, { useState } from "react";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Modal,
  Box,
  Button,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw", // Adjusted for better fit
  height: "90vh", // Adjusted for better fit
  overflow: "auto",
  bgcolor: "background.paper",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const headerStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  bgcolor: "#f1f1f1", // Light gray background for the header
  p: 2,
};

const closeButtonStyle = {
  bgcolor: "#d32f2f", // Red background color for the button
  color: "#fff", // White text color
  "&:hover": {
    bgcolor: "#9a0007", // Darker red on hover
  },
};

type ServiceCardProps = {
  data: {
    image: string;
    label: string;
    shortDescription: string;
    pdfName: string;
    alt: string;
  };
};

const ServiceCard: React.FC<ServiceCardProps> = ({ data }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const pdfUrl = `/pdf/${data.pdfName}.pdf`;

  const zoomPluginInstance = zoomPlugin();

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <CardActionArea
          onClick={handleOpen}
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <CardMedia
            component="img"
            sx={{ height: 140, objectFit: "cover" }}
            image={data.image}
            alt={data.alt}
          />
          <CardContent
            sx={{
              flexGrow: 1,
              minHeight: 140,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Typography gutterBottom variant="h2">
              <h2 className="card-label">{data.label}</h2>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {data.shortDescription}
            </Typography>
          </CardContent>
        </CardActionArea>
        <Button
          onClick={handleOpen}
          sx={{
            alignSelf: "flex-end",
            textDecoration: "underline",
            color: "teal",
            m: 1,
          }}
        >
          Click Here to Know More
        </Button>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box sx={style}>
          <Box sx={headerStyle}>
            <Typography variant="h6" component="h2">
              {data.label}
            </Typography>
            <Button onClick={handleClose} sx={closeButtonStyle}>
              Close
            </Button>
          </Box>
          <Worker
            workerUrl={`${process.env.PUBLIC_URL}/scripts/pdf.worker.min.js`}
          >
            <Viewer
              fileUrl={pdfUrl}
              plugins={[zoomPluginInstance]}
              defaultScale={SpecialZoomLevel.PageFit}
            />
          </Worker>
        </Box>
      </Modal>
    </Grid>
  );
};

export default ServiceCard;
